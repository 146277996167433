

















import Vue from "vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppUsersTable from "@/components/tables/AppUsersTable.vue";
import {
  ApiException,
  OrderBy,
  UsersAdminClient,
  UserSort,
  UserSummaryDto,
} from "@/clients/clients";
import { DataOptions } from "vuetify";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "UsersDashboardView",
  components: { AppUsersTable, AppPageBanner, AppPage },
  data: () => ({
    loading: false,
    totalUsers: 0,
    users: [] as UserSummaryDto[],
    noDataText: "",
    tableOptions: {
      page: 1,
      itemsPerPage: 15,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
  }),
  async created() {
    await this.getUsers();
  },
  methods: {
    async updateOptions(options: DataOptions): Promise<void> {
      this.tableOptions = Object.assign({}, this.tableOptions, options);
      await this.getUsers();
    },
    async resetOptions(): Promise<void> {
      this.tableOptions = Object.assign({}, this.tableOptions, {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      });
      await this.getUsers();
    },
    async getUsers(): Promise<void> {
      this.loading = true;
      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          UsersAdminClient,
          token.accessToken
        );
        const query = this.query();
        const result = await client.getUsers(
          query.all,
          query.offset,
          query.limit,
          query.verified,
          query.blocked,
          query.sort,
          query.order
        );
        this.users = result.users ?? [];
        this.totalUsers = result.total ?? 0;
      } catch (e) {
        this.noDataText = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    query(): {
      all?: boolean | undefined;
      offset?: number | undefined;
      limit?: number | undefined;
      verified?: boolean | undefined;
      blocked?: boolean | undefined;
      sort?: UserSort | undefined;
      order?: OrderBy | undefined;
    } {
      const query: {
        all?: boolean | undefined;
        offset?: number | undefined;
        limit?: number | undefined;
        verified?: boolean | undefined;
        blocked?: boolean | undefined;
        sort?: UserSort | undefined;
        order?: OrderBy | undefined;
      } = {
        all: this.tableOptions.itemsPerPage === -1,
        offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
        limit: this.tableOptions.itemsPerPage,
        verified: undefined,
        blocked: undefined,
        sort: undefined,
        order: undefined,
      };

      if (
        this.tableOptions.sortBy.length &&
        this.tableOptions.sortDesc.length
      ) {
        query.order = this.tableOptions.sortDesc[0]
          ? OrderBy.DESC
          : OrderBy.ASC;
        switch (this.tableOptions.sortBy[0]) {
          case "firstName":
            query.sort = UserSort.ByFirstName;
            break;
          case "lastName":
            query.sort = UserSort.ByLastName;
            break;
          case "email":
            query.sort = UserSort.ByEmail;
            break;
          case "verified":
            query.sort = UserSort.ByVerified;
            break;
          case "blocked":
            query.sort = UserSort.ByBlocked;
            break;
          case "createdAt":
            query.sort = UserSort.BySignupDate;
            break;
          case "updatedAt":
            query.sort = UserSort.ByLastUpdated;
            break;
          default:
            query.sort = UserSort.None;
            break;
        }
      }
      return query;
    },
  },
});
